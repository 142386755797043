<template>
  <NuxtLayout>
    <NuxtPage />

    <ClientOnly>
      <Notifications
        class="pointer-events-none mt-2 select-none"
        position="bottom center"
        :duration="4000"
        :max="4"
        :ignore-duplicates="true"
        width="100%"
      >
        <template #body="{item, close}">
          <UiAlert
            class="pointer-events-all mx-auto my-4 max-w-[400px]"
            :type="item.type"
            :title="item.title"
            @click="close"
          >
            <span>{{ item.text }}</span>
          </UiAlert>
        </template>
      </Notifications>
    </ClientOnly>

    <UiModalInstance
      v-for="modal in modals.modals.value"
      :key="modal.id"
      :modal="modal"
    />
  </NuxtLayout>
</template>

<script setup lang="ts">
import {Notifications} from '@kyvg/vue3-notification';

const modals = useModal();

useHead({
  titleTemplate: (title) => {
    return title ? `${title} - Censes Portal` : 'Censes Portal';
  },
});
</script>

