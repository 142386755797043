<template>
  <Dialog
    class="relative z-50"
    :open="true"
    @close="modal.close"
  >
    <div
      class="fixed inset-0 bg-black/70 backdrop-blur-sm"
      aria-hidden="true"
    />
    <div class="fixed inset-0 flex w-screen items-center justify-center p-4">
      <DialogPanel class="card rounded-2xl p-0">
        <div class="p-6">
          <div class="flex items-center justify-between">
            <span
              v-if="type === 'destructive'"
              class="flex rounded-full bg-red-100 p-3"
            >
              <Icon
                name="mdi:alert-outline"
                class="size-6 text-red-400"
              />
            </span>
            <span
              v-else-if="type === 'input'"
              class="flex rounded-full bg-gray-100 p-2"
            >
              <Icon
                name="mdi:flag-outline"
                class="size-8 text-gray-400"
              />
            </span>
            <button
              type="button"
              @click="modal.close"
            >
              <Icon
                name="mdi:close"
                class="size-8 text-gray-400"
              />
            </button>
          </div>
          <DialogTitle class="my-4">
            <slot name="title">
              {{ title }}
            </slot>
          </DialogTitle>
          <DialogDescription>
            <slot name="description">
              {{ description }}
            </slot>
          </DialogDescription>
        </div>
        <div class="p-6">
          <slot />
        </div>
        <div class="p-6">
          <slot name="actions" />
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue';

defineProps<{
  description: string
  title: string
  type: 'destructive' | 'input'
}>();

const modal = useModalInstance();
</script>
