<template>
  <UiModal
    :title="title"
    :description="description"
    :type="type"
  >
    <template #actions>
      <div class="flex justify-between gap-4">
        <UiButton
          class="basis-1/2 justify-center"
          :label="cancelLabel ?? 'Annuleren'"
          @click="cancel"
        />
        <UiButton
          class="basis-1/2 justify-center"
          :type="type === 'destructive' ? 'destructive' : 'submit'"
          :label="confirmLabel ?? 'Doorgaan'"
          @click="confirm"
        />
      </div>
    </template>
  </UiModal>
</template>

<script setup lang="ts">
defineProps<{
  title: string
  description: string
  type: 'destructive' | 'input'
  confirmLabel?: string
  cancelLabel?: string
}>();

const emit = defineEmits<{
  cancel: [],
  confirm: [],
}>();

const modal = useModalInstance();

function confirm(): void {
  emit('confirm');
  modal.close();
}

function cancel(): void {
  emit('cancel');
  modal.close();
}
</script>
