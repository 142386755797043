import revive_payload_client_yrOiUka5np from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_eslint@8.57.0_ioredis@5._zscoesafrnatffywfcxwbaamoq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_PF63gMQhNW from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_eslint@8.57.0_ioredis@5._zscoesafrnatffywfcxwbaamoq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_bBGr7Y0I69 from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_eslint@8.57.0_ioredis@5._zscoesafrnatffywfcxwbaamoq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_sjSLJ8A0jO from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@22.2.0_terser@5.3_zbe5cyip5tbwmvldxgdlqvzqwq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_8EDn79F4Bb from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_eslint@8.57.0_ioredis@5._zscoesafrnatffywfcxwbaamoq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_6phUXMQ1Je from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_eslint@8.57.0_ioredis@5._zscoesafrnatffywfcxwbaamoq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_577dF6cIUu from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_eslint@8.57.0_ioredis@5._zscoesafrnatffywfcxwbaamoq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_5ddblUQaUs from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_eslint@8.57.0_ioredis@5._zscoesafrnatffywfcxwbaamoq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_MlIJfoxc71 from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_eslint@8.57.0_ioredis@5._zscoesafrnatffywfcxwbaamoq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_0qmE5hKOOv from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.4_rollup@4.20.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import switch_locale_path_ssr_nNWGREBAzW from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.20.0_vue@3.4.37_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_uC79B9tVYf from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.20.0_vue@3.4.37_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import formkitPlugin_pZqjah0RUG from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/.nuxt/formkitPlugin.mjs";
import plugin_BOnaA0ZEx4 from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-module-apollo@1.1.1_graphql-ws@5.16.0_graphql@16.8.2__react-dom@18.3.1_react@_hkru5jwp4ndk6ppvekxed6wedi/node_modules/@bttr-devs/nuxt-module-apollo/dist/runtime/plugin.js";
import plugin_TucqyBw2X3 from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_encoding@0.1.13_magicast@0.3.4_next-auth@4.21.1_next@13.5.6_@babel+_4a4ujtzeolg2kijwqa7pggcwwq/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import errors_1CZeloWvH9 from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.3_@bugsnag+core@7.25.0_magicast@0.3.4_nuxt@3.12.4_@parcel+watc_fichox357hl5duq7q56kshorha/node_modules/@bttr-devs/nuxt-layer-base/plugins/errors.ts";
import i18n_sp488CoLoy from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.3_@bugsnag+core@7.25.0_magicast@0.3.4_nuxt@3.12.4_@parcel+watc_fichox357hl5duq7q56kshorha/node_modules/@bttr-devs/nuxt-layer-base/plugins/i18n.ts";
import apollo_r0ToXfS2MD from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.3_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.8.2__magi_ubzk6ehcfroicwjis2iaekdipe/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/apollo.ts";
import sofie_required_version_FQnBIzVPXd from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.3_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.8.2__magi_ubzk6ehcfroicwjis2iaekdipe/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/sofie-required-version.ts";
import apollo_hw3MwHlpG0 from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.2_@bugsnag+core@7.25.0_encoding@0.1.13_graphql-ws@5.16.0_zsfeilawdeqkcbwcvrdalzmr2e/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/apollo.ts";
import auth_7AF9xaqLRE from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.2_@bugsnag+core@7.25.0_encoding@0.1.13_graphql-ws@5.16.0_zsfeilawdeqkcbwcvrdalzmr2e/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/auth.ts";
export default [
  revive_payload_client_yrOiUka5np,
  unhead_PF63gMQhNW,
  router_bBGr7Y0I69,
  _0_siteConfig_sjSLJ8A0jO,
  payload_client_8EDn79F4Bb,
  navigation_repaint_client_6phUXMQ1Je,
  check_outdated_build_client_577dF6cIUu,
  chunk_reload_client_5ddblUQaUs,
  components_plugin_KR1HBZs4kY,
  prefetch_client_MlIJfoxc71,
  plugin_0qmE5hKOOv,
  switch_locale_path_ssr_nNWGREBAzW,
  i18n_uC79B9tVYf,
  formkitPlugin_pZqjah0RUG,
  plugin_BOnaA0ZEx4,
  plugin_TucqyBw2X3,
  errors_1CZeloWvH9,
  i18n_sp488CoLoy,
  apollo_r0ToXfS2MD,
  sofie_required_version_FQnBIzVPXd,
  apollo_hw3MwHlpG0,
  auth_7AF9xaqLRE
]