<template>
  <PageLayout :breadcrumbs="breadcrumbs">
    <div class="flex h-full flex-col items-center justify-center gap-5">
      <div class="text-6xl font-bold">
        {{ error.statusCode }}
      </div>
      <h1 class="mb-5 text-3xl font-bold">
        <template v-if="error.statusCode === 404">
          We kunnen deze pagina niet (meer) vinden
        </template>
        <template v-else>
          {{ error.message }}
        </template>
      </h1>

      <UiButton
        label="Terug naar dashboard"
        @click="handleError"
      />
    </div>
  </PageLayout>
</template>

<script setup lang="ts">
const props = defineProps<{
  error: {
    message: string
    statusCode: number
  },
}>();

useSeoMeta({
  title: props.error.message,
});

const router = useRouter();
const breadcrumbs = [{label: 'Pagina niet gevonden', route: router.currentRoute.value}];

function handleError() {
  clearError({redirect: '/'});
}
</script>
