import validate from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_eslint@8.57.0_ioredis@5._zscoesafrnatffywfcxwbaamoq/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.3_@bugsnag+core@7.25.0_magicast@0.3.4_nuxt@3.12.4_@parcel+watc_fichox357hl5duq7q56kshorha/node_modules/@bttr-devs/nuxt-layer-base/middleware/redirect-trailing-slash.global.ts";
import manifest_45route_45rule from "/data/www/dashboard.censes.nl/censes-dashboard/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.2.0_encoding@0.1.13_eslint@8.57.0_ioredis@5._zscoesafrnatffywfcxwbaamoq/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "sofie-page": () => import("/data/www/dashboard.censes.nl/censes-dashboard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.3_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.8.2__magi_ubzk6ehcfroicwjis2iaekdipe/node_modules/@bttr-devs/nuxt-layer-sofie/middleware/sofie-page.ts"),
  auth: () => import("/data/www/dashboard.censes.nl/censes-dashboard/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_encoding@0.1.13_magicast@0.3.4_next-auth@4.21.1_next@13.5.6_@babel+_4a4ujtzeolg2kijwqa7pggcwwq/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}