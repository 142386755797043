<template>
  <component
    :is="to ? NuxtLink : 'button'"
    :to="to"
    class="flex size-fit items-center rounded-lg border transition-all focus:outline-none focus:ring-4 disabled:cursor-not-allowed"
    :class="computedClasses"
    :type="to ? undefined : type === 'submit' ? 'submit' : 'button'"
  >
    <Icon
      v-if="icon && iconPosition === 'left'"
      :name="icon"
      class="size-6"
    />
    <slot>
      {{ label }}
    </slot>
    <Icon
      v-if="icon && iconPosition === 'right'"
      :name="icon"
      class="size-6"
    />
  </component>
</template>

<script setup lang="ts">
import type {RouteLocationRaw} from 'vue-router';
import {NuxtLink} from '#components';

type Type = 'submit' | 'default' | 'destructive'
type Style = {
  color?: 'brand' | 'neutral' | 'destructive'
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
  variant?: 'filled' | 'outline' | 'blank'
}

const props = withDefaults(defineProps<{
  label?: string | undefined
  icon?: string | undefined
  iconPosition?: 'left' | 'right'
  type?: Type
  to?: RouteLocationRaw
} & Style>(), {
  label: undefined,
  icon: undefined,
  size: 'md',
  color: 'brand',
  variant: 'filled',
  iconPosition: 'right',
  to: undefined,
  type: 'default',
});

const sizeScheme = {
  sm: 'px-3 py-2 gap-1',
  md: 'px-3.5 py-2.5 gap-1',
  lg: 'px-4 py-2.5 gap-1.5',
  xl: 'px-5 py-3 gap-1.5',
  '2xl': 'px-6 py-4 gap-2.5',
};

const iconButtonScheme = {
  sm: 'p-2',
  md: 'p-2.5',
  lg: 'p-3',
  xl: 'p-3.5',
  '2xl': 'p-4',
};

const variantScheme = {
  filled: 'focus:ring-2 focus:ring-opacity-50 text-white disabled:bg-gray-100 disabled:text-gray-400 disabled:border-gray-300',
  blank: 'focus:ring-2 focus:ring-opacity-50 bg-transparent border-none disabled:bg-transparent disabled:text-gray-400 disabled:border-gray-300',
  outline: 'focus:ring-2 focus:ring-opacity-50 bg-white border disabled:bg-white disabled:border-gray-200 disabled:text-gray-400 disabled:border-gray-300',
};

const colorScheme = {
  brand: {
    base: 'focus:ring-brand-200',
    filled: 'bg-brand-500 border-brand-700 hover:bg-brand-700 hover:border-brand-900',
    blank: 'text-brand-600 hover:bg-brand-50 hover:border-brand-50 hover:text-brand-900',
    outline: 'border-brand-300 text-brand-700 hover:bg-brand-50 hover:border-brand-300 hover:text-brand-900',
  },
  destructive: {
    base: 'focus:ring-red-200',
    filled: 'bg-red-500 border-red-700 hover:bg-red-700 hover:border-red-900',
    blank: 'text-red-600 hover:bg-red-50 hover:border-red-50 hover:text-red-900',
    outline: 'border-red-300 text-red-700 hover:bg-red-50 hover:border-red-300 hover:text-red-900',
  },
  neutral: {
    base: 'focus:ring-gray-200',
    filled: 'bg-gray-500 border-gray-700 hover:bg-gray-700 hover:border-gray-900',
    blank: 'text-gray-600 hover:bg-gray-50 hover:border-gray-50 hover:text-gray-900',
    outline: 'border-gray-300 text-gray-700 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-900',
  },
};

const presets: { [key in Type]: Style } = {
  submit: {color: 'brand', variant: 'filled'},
  default: {color: 'neutral', variant: 'outline'},
  destructive: {color: 'destructive', variant: 'filled'},
};

const computedClasses = computed(() => {
  const presetConfig = presets[props.type] || {};
  const color = presetConfig.color || props.color;
  const variant = presetConfig.variant || props.variant;

  const size = (props.label && props.icon) ? sizeScheme[props.size] : iconButtonScheme[props.size];

  return [
    size,
    variantScheme[variant],
    colorScheme[color].base,
    colorScheme[color][variant],
  ];
});
</script>
