<template>
  <div class="flex min-w-64 shrink-0 flex-col border-r border-r-gray-200 px-4 py-8">
    <NuxtImg
      width="155"
      src="/censes-logo.svg"
      class="mb-6 shrink-0"
    />
    <ul class="menu flex w-full grow flex-col gap-0.5 p-0">
      <li
        v-for="item in menuItems"
        :key="item.label"
      >
        <MenuListItem
          class="text-brand-800"
          :icon="item.icon"
          :to="item.route"
        >
          {{ item.label }}
        </MenuListItem>
      </li>
      <li class="mt-auto">
        <hr>
        <button
          type="button"
          class="menu-item"
          @click="signOut"
        >
          <Icon
            name="mdi:logout"
            class="size-6"
          />
          Uitloggen
        </button>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import type {RouteLocationRaw} from 'vue-router';

const {signOut} = useAuth();

defineProps<{
  menuItems: Array<{
    label: string,
    route: RouteLocationRaw,
    icon?: string
  }>;
}>();
</script>
