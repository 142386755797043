import type {Component} from 'vue';
import {UiModalConfirm} from '#components';

interface Modal {
  component: Component
  props?: Record<string, unknown>
  events?: Record<string, unknown>
}

export const ModalInstanceSymbol = Symbol('ModalInstanceSymbol');

export interface ModalInstance extends Modal {
  id: string
  // close: () => void
}

const modals = ref<ModalInstance[]>([]);

export function useModal() {
  function close(instance: ModalInstance) {
    modals.value.splice(modals.value.indexOf(instance), 1);
  }

  function open(modal: Modal) {
    const id = Math.floor(Math.random() * 1000).toString();

    const instance = {
      ...modal,
      component: toRaw(modal.component),
      id,
    } as const;

    modals.value.push(instance);

    return {
      close: () => close(instance),
    };
  }

  function confirm(options: {
    title: string,
    description: string,
    type?: 'input' | 'destructive',
    confirmLabel?: string,
    cancelLabel?: string,
  }) {
    const {promise, resolve} = Promise.withResolvers();

    open({
      component: UiModalConfirm,
      props: options,
      events: {
        'confirm': () => resolve(true),
        'close': () => resolve(false),
      },
    });

    return promise;
  }

  return {
    confirm,
    open,
    close,
    modals: readonly(modals),
  };
}

export function useModalInstance() {
  const modal = inject<ModalInstance>(ModalInstanceSymbol);
  const {close} = useModal();

  if (!modal) {
    throw new Error('Not in modal context');
  }

  return {
    modal,
    close: () => close(modal),
  };
}
