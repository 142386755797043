import {nl} from '@formkit/i18n';
import {createConfig} from '@formkit/core';
import {generateClasses} from '@formkit/themes';
import {createMultiStepPlugin} from '@formkit/addons';
import {createProPlugin, datepicker} from '@formkit/pro-unlimited';
import {formKitIcons} from '~/utils/icon';
import {genesisIcons, icons} from '@formkit/icons';
import {createAsteriskPlugin} from '~/utils/formkit-asterisk';

export default createConfig({
  locales: {nl},
  locale: 'nl',
  plugins: [createAsteriskPlugin, createMultiStepPlugin(), createProPlugin('fk-4b3cc20229', {datepicker})],
  icons: {...formKitIcons, ...icons, ...genesisIcons},
  config: {
    classes: generateClasses({
      // Global styles apply to _all_ inputs with matching section keys
      global: {
        fieldset: 'max-w-md border border-gray-400 rounded px-2 pb-1',
        help: 'text-xs text-gray-500',
        inner: 'formkit-disabled:bg-gray-200 formkit-disabled:cursor-not-allowed formkit-disabled:pointer-events-none',
        input: 'mr-3 w-4 bg-transparent focus:outline-none focus:ring-0 focus:shadow-none',
        label: 'block mb-1.5 text-base text-gray-700',
        legend: 'font-bold text-sm',
        loaderIcon: 'inline-flex items-center w-4 text-gray-600 animate-spin',
        message: 'text-red-500 mb-1 text-xs',
        messages: 'list-none p-0 mt-1 mb-0',
        outer: 'mb-6 formkit-disabled:opacity-50',
        prefixIcon: 'w-6 flex self-stretch grow-0 shrink-0 rounded-tl rounded-bl border-r border-gray-400 bg-white bg-gradient-to-b from-transparent to-gray-200 [&>svg]:w-full [&>svg]:m-auto box-content',
        suffixIcon: 'w-6 p-3 flex self-stretch grow-0 shrink-0 [&>svg]:w-full [&>svg]:m-auto box-content',
      },

      // Family styles apply to all inputs that share a common family
      'family:box': {
        decorator: 'block relative h-5 w-5 mr-2 rounded bg-white ring-1 ring-gray-400 peer-checked:ring-brand-500 text-transparent peer-checked:text-white peer-checked:bg-brand-500 peer-checked:border-brand-500',
        decoratorIcon: 'flex !p-1 w-4 h-4 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 box-content',
        help: 'mb-2 mt-1.5',
        input: 'absolute w-0 h-0 overflow-hidden opacity-0 pointer-events-none peer border-none',
        inner: '$remove:formkit-disabled:bg-gray-200',
        label: '$reset text-sm text-gray-700 mt-1 select-none',
        wrapper: 'flex items-center mb-1',
      },
      'family:button': {
        input: '$reset w-full justify-center formkit-loading:before:size-4 formkit-loading:before:mr-2 formkit-loading:before:border formkit-loading:before:border-2 formkit-loading:before:border-r-transparent formkit-loading:before:rounded-3xl formkit-loading:before:border-white formkit-loading:before:animate-spin flex size-fit items-center gap-1 rounded-lg border border-brand-700 bg-brand-500 px-3.5 py-2.5 text-white transition-all hover:border-brand-900 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-4 focus:ring-brand-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-100 disabled:text-gray-400',
        wrapper: 'mt-10',
        prefixIcon: '$reset block w-4 -ml-2 mr-2 stretch box-content',
        suffixIcon: '$reset block w-4 ml-2 stretch box-content',
      },
      'family:dropdown': {
        dropdownWrapper: 'my-2 w-full shadow-lg rounded [&::-webkit-scrollbar]:hidden',
        emptyMessageInner: 'flex items-center justify-center text-sm p-2 text-center w-full text-gray-500 [&>span]:mr-3 [&>span]:ml-0',
        inner: 'max-w-md relative flex ring-1 ring-gray-400 focus-within:ring-blue-500 focus-within:ring-2 rounded mb-1 formkit-disabled:focus-within:ring-gray-400 formkit-disabled:focus-within:ring-1 [&>span:first-child]:focus-within:text-blue-500',
        input: 'w-full px-3 py-2',
        listbox: 'bg-white shadow-lg rounded overflow-hidden',
        listboxButton: 'flex w-12 self-stretch justify-center mx-auto',
        listitem: 'pl-7 relative hover:bg-gray-300 data-[is-active="true"]:bg-gray-300 aria-selected:bg-blue-600 aria-selected:text-white data-[is-active="true"]:aria-selected:bg-blue-600 data-[is-active="true"]:aria-selected:bg-blue-700',
        loaderIcon: 'ml-auto box-content',
        loadMoreInner: 'flex items-center justify-center text-sm p-2 text-center w-full text-blue-500 formkit-loading:text-gray-500 cursor-pointer [&>span]:mr-3 [&>span]:ml-0',
        option: 'p-2.5',
        optionLoading: 'pl-2.5 text-gray-400',
        placeholder: 'p-2.5 text-gray-400',
        selector: 'flex w-full justify-between items-center min-h-[2.625em] [&u] cursor-default',
        selection: 'flex w-full',
        selectedIcon: 'block absolute top-1/2 left-2 w-3 -translate-y-1/2 box-content',
        selectIcon: 'flex box-content w-4 px-2 self-stretch grow-0 shrink-0 [&>svg]:w-[1em] cursor-pointer box-content',
      },
      'family:text': {
        outer: 'w-full',
        inner: 'flex items-center bg-white border [&>label:first-child]:focus-within:text-blue-500 rounded-lg mb-1',
        input: 'w-full px-4 py-3 border-none text-base text-gray-700 placeholder-gray-400',
      },
      'family:date': {
        inner: 'flex items-center max-w-md ring-1 ring-gray-400 focus-within:ring-blue-500 focus-within:ring-2 [&>label:first-child]:focus-within:text-blue-500 rounded mb-1',
        input: 'w-full px-3 py-2 border-none text-gray-700 placeholder-gray-400',
      },

      // Specific styles apply only to a given input type
      color: {
        inner: 'flex max-w-[5.5em] w-full formkit-prefix-icon:max-w-[7.5em] formkit-suffix-icon:formkit-prefix-icon:max-w-[10em]',
        input: '$reset appearance-none w-full cursor-pointer border-none rounded p-0 m-0 bg-transparent [&::-webkit-color-swatch-wrapper]:p-0 [&::-webkit-color-swatch]:border-none',
        suffixIcon: 'min-w-[2.5em] pr-0 pl-0 m-auto',
      },
      datepicker: {
        inner: 'relative',
        panelWrapper: 'absolute top-[calc(100%_+_0.5em)] shadow-[0_0_1.25em_rgba(0,0,0,.25)] rounded-md p-5 bg-white z-10',
        panelHeader: 'grid grid-cols-[2.5em_1fr_2.5em] justify-center items-center border-b-2 mb-4 pb-4',
        input: 'selection:bg-blue-400',
        monthsHeader: 'flex items-center justify-center col-start-2 col-end-2',
        timeHeader: 'flex items-center justify-center col-start-2 col-end-2',
        overlayPlaceholder: 'text-gray-400',
        months: 'flex flex-wrap',
        month: `
          flex items-center justify-center
          w-[calc(33%_-_1em)] m-2 p-2 rounded-md
          bg-gray-200
          aria-selected:bg-blue-500 aria-selected:text-light
          focus:outline focus:outline-2 focus:outline-blue-500 focus:outline-offset-2 focus:bg-white focus:text-black
          data-[is-extra=true]:opacity-25
          formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none
        `,
        yearsHeader: 'flex items-center justify-center col-start-2 col-end-2',
        years: 'flex flex-wrap max-w-[35em]',
        year: `
          flex items-center justify-center
          w-[calc(20%_-_1em)] m-2 p-2 rounded-md
          bg-gray-200
          aria-selected:bg-blue-500 aria-selected:text-light
          focus:outline focus:outline-2 focus:outline-blue-500 focus:outline-offset-2 focus:bg-white focus:text-black
          data-[is-extra=true]:opacity-25
          formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none
        `,
        weekDays: 'flex',
        weekDay: 'flex w-[2.25em] h-[1em] m-1 items-center justify-center rounded-md font-medium lowercase',
        week: 'flex formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none',
        dayCell: `
          flex items-center justify-center cursor-pointer w-[2.25em] h-[2.25em] m-1 p-2 rounded-md bg-gray-200
          aria-selected:bg-blue-500 aria-selected:text-light
          focus:outline focus:outline-2 focus:outline-blue-500 focus:outline-offset-2 focus:bg-white focus:text-black
          data-[is-extra=true]:opacity-25
          formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none
        `,
        timeInput: 'w-full border-2 border-border-default rounded-md p-2 my-[2em] focus-visible:outline-blue-500',
        daysHeader: 'flex items-center justify-center',
        prev: 'mr-auto px-3 py-1 hover:bg-gray-100 hover:rounded-md col-start-1 col-end-1',
        prevLabel: 'hidden',
        prevIcon: 'flex w-3 select-none [&>svg]:w-full box-content',
        dayButton: 'appearance-none cursor-pointer px-3 py-1 border-2 rounded-md mx-1 hover:border-blue-500',
        monthButton: 'appearance-none cursor-pointer px-3 py-1 border-2 rounded-md mx-1 hover:border-blue-500',
        yearButton: 'appearance-none cursor-pointer px-3 py-1 border-2 rounded-md mx-1 hover:border-blue-500',
        next: 'ml-auto px-3 py-1 hover:bg-gray-100 hover:rounded-md col-start-3 col-end-3',
        nextLabel: 'hidden',
        nextIcon: 'flex w-3 select-none [&>svg]:w-full box-content',
        openButton: `
          appearance-none border-0 bg-transparent flex p-0 self-stretch cursor-pointer
          focus-visible:outline-none focus-visible:text-text-light focus-visible:bg-blue-500
        `,
        calendarIcon: 'flex w-12 grow-0 shrink-0 self-stretch select-none [&>svg]:w-full [&>svg]:m-auto [&>svg]:max-h-[1em] [&>svg]:max-w-[1em]',
      },
      file: {
        fileItem: 'flex items-center text-gray-800 mb-1 last:mb-0',
        fileItemIcon: 'w-4 mr-2 shrink-0 box-content',
        fileList: 'shrink grow peer px-3 py-2 formkit-multiple:data-[has-multiple="true"]:mb-6',
        fileName: 'break-all grow text-ellipsis',
        fileRemove: 'relative z-[2] ml-auto text-[0px] hover:text-red-500 pl-2 peer-data-[has-multiple=true]:text-sm peer-data-[has-multiple=true]:text-blue-500 peer-data-[has-multiple=true]:ml-3 peer-data-[has-multiple=true]:mb-2 formkit-multiple:bottom-[0.15em] formkit-multiple:pl-0 formkit-multiple:ml-0 formkit-multiple:left-[1em] formkit-multiple:formkit-prefix-icon:left-[3.75em]',
        fileRemoveIcon: 'block text-base w-3 relative z-[2] box-content',
        inner: 'relative max-w-md cursor-pointer formkit-multiple:[&>button]:absolute',
        input: 'cursor-pointer text-transparent absolute top-0 right-0 left-0 bottom-0 opacity-0 z-[2]',
        noFiles: 'flex w-full items-center px-3 py-2 text-gray-400',
        noFilesIcon: 'w-4 mr-2 box-content',
      },
      radio: {
        decorator: 'rounded-full',
        decoratorIcon: 'w-5 p-[5px] box-content',
      },
      range: {
        inner: '$reset flex items-center max-w-md',
        input: '$reset w-full mb-1 h-2 p-0 rounded-full',
        prefixIcon: '$reset w-4 mr-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
        suffixIcon: '$reset w-4 ml-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
      },
      select: {
        inner: 'flex w-full relative border items-center rounded-lg mb-1 [&>span:first-child]:focus-within:text-blue-500',
        input: 'w-full pl-3 pr-8 py-3 border-none text-base text-gray-700 placeholder-gray-400 formkit-multiple:p-0 data-[placeholder="true"]:text-gray-400 formkit-multiple:data-[placeholder="true"]:text-inherit',
        option: 'formkit-multiple:p-3 formkit-multiple:text-sm text-gray-700',
      },
      textarea: {
        inner: 'flex rounded-lg mb-1 border [&>label:first-child]:focus-within:text-blue-500',
        input: 'block w-full h-32 px-3 py-3 border-none text-base text-gray-700 placeholder-gray-400 focus:shadow-outline',
      },
    }),
  },
});
