const select =
	'<svg viewBox="0 0 18 10" xmlns="http://www.w3.org/2000/svg"><path d="M16.8984 2.19533L9.77339 8.90315C9.55777 9.1469 9.27652 9.25002 8.99995 9.25002C8.72339 9.25002 8.44308 9.14746 8.22651 8.94243L1.10152 2.19533C0.650109 1.76408 0.631359 1.05158 1.05745 0.601585C1.48542 0.146897 2.20027 0.132835 2.64839 0.559398L8.99995 6.57346L15.3515 0.55471C15.7997 0.128429 16.5117 0.144554 16.9425 0.598655C17.3671 1.05158 17.3484 1.76408 16.8984 2.19533Z" fill="currentColor"/></svg>';

const checkbox =
	'<svg  viewBox="0 0 22 16" xmlns="http://www.w3.org/2000/svg"><path d="M21.1297 0.828115C21.6078 1.26874 21.6078 1.98124 21.1297 2.37968L8.75469 14.7547C8.35625 15.2328 7.64375 15.2328 7.20312 14.7547L0.829484 8.37968C0.390172 7.98124 0.390172 7.26874 0.829484 6.82812C1.26875 6.39218 1.98125 6.39218 2.42047 6.82812L8 12.4109L19.5781 0.828115C20.0187 0.390303 20.7313 0.390303 21.1297 0.828115Z" fill="currentColor"/></svg>';

const search =
	'<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M15.7529 14.7188L11.5654 10.5312C12.4716 9.42188 12.9716 8.025 12.9716 6.5C12.9716 2.90937 10.0613 0 6.47164 0C2.88195 0 -0.000244141 2.91031 -0.000244141 6.5C-0.000244141 10.0897 2.90976 13 6.47164 13C7.99633 13 9.39508 12.4716 10.5029 11.5925L14.6904 15.78C14.8654 15.9281 15.0591 16 15.2498 16C15.4404 16 15.6335 15.9268 15.7801 15.7803C16.0716 15.4875 16.0716 15.0125 15.7529 14.7188ZM1.49976 6.5C1.49976 3.74312 3.74289 1.5 6.49976 1.5C9.25664 1.5 11.4998 3.74312 11.4998 6.5C11.4998 9.25688 9.25664 11.5 6.49976 11.5C3.74289 11.5 1.49976 9.25625 1.49976 6.5Z" fill="currentColor"/></svg>';

/**
 * Add this to the formkit.config.ts createConfig
 *
 * icons: { ...formKitIcons, },
 *
 * @return {{select?: string, search?: string, checkboxDecorator?: string, radioDecorator?: string}} Object with default FormKit icons
 *
 */
export const formKitIcons = {
  checkboxDecorator: checkbox,
  radioDecorator: checkbox,
  select: select,
  searchIcon: search,
};
