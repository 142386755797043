<template>
  <div
    class="flex space-x-2 rounded-md border p-5"
    :class="alert.classes"
    role="alert"
  >
    <div class="flex w-10 pt-1">
      <Icon
        :class="alert.iconClasses"
        :name="alert.icon"
      />
    </div>
    <div class="flex flex-col">
      <span
        class="text-lg font-medium"
        :class="`!text-text-${props.type}`"
        v-text="alert.title"
      />

      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  type: 'information' | 'success' | 'warning' | 'error'
  title?: string
}>();

const alert = computed(() => {
  switch (props.type) {
    case 'information':
      return {
        classes: 'text-[#1E40AF] bg-[#BFDBFE] border border-[#2563EB]',
        icon: 'heroicons:information-circle',
        iconClasses: 'size-5 text-[#1E40AF]',
        title: props.title ?? 'Informatie',
      };
    case 'success':
      return {
        classes: 'text-[#065F46] bg-[#D1FAE5] border border-[#059669]',
        icon: 'heroicons:check-circle',
        iconClasses: 'size-5 text-[#065F46]',
        title: props.title ?? 'Gelukt!',
      };
    case 'warning':
      return {
        classes: 'text-[#92400E] bg-[#FDE68A] border border-[#D97706]',
        icon: 'heroicons:exclamation-triangle',
        iconClasses: 'size-5 text-[#92400E]',
        title: props.title ?? 'Let op!',
      };
    case 'error':
      return {
        classes: 'text-[#B91C1C] bg-[#FECACA] border border-[#DC2626]',
        icon: 'heroicons:x-circle',
        iconClasses: 'size-5 text-[#B91C1C]',
        title: props.title ?? 'Fout!',
      };
  }
});
</script>
