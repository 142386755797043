<template>
  <div class="flex min-h-dvh">
    <AppSideBar
      :menu-items="menuItems"
    />
    <main class="flex h-screen grow flex-col overflow-hidden">
      <header class="shrink-0 border-b border-gray-200 p-6">
        <UiBreadcrumbs
          :links="breadcrumbs"
          class="mb-5"
        />

        <div class="flex justify-between">
          <div>
            <slot name="title" />
            <slot name="link" />
          </div>
          <slot name="button" />
        </div>
      </header>

      <div class="grow overflow-y-auto bg-gray-50 p-6">
        <slot />
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
import type {TypeBreadcrumbsLinks} from '~/components/ui/Breadcrumbs.vue';

defineProps<{
  breadcrumbs: TypeBreadcrumbsLinks
}>();

const menuItems = [
  {label: 'Dashboard', route: '/', icon: 'mdi:home-outline'},
  {label: 'Programma’s', route: '/programmas', icon: 'mdi:layers-triple-outline'},
  {label: 'Deelnemers', route: '/deelnemers', icon: 'mdi:account-multiple-outline'},
];
</script>
